$prefooter-spacing-y: 4rem;

.prefooter {
    $p: &;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    text-align: center;
    background-size: cover;

    &--dark {
        min-height: 400px;
        color: #fff;
        background: $gray-darkest var(--prefooter-bg, url('/static/img/prefooter-bg.jpg')) no-repeat
            center center;

        &::after {
            @include coverall;
            content: '';
            display: block;
            background-image: linear-gradient(
                to bottom,
                rgba($gray-darkest, 0.65) 0%,
                rgba($gray-darkest, 0.65) 70%,
                rgba($gray-darkest, 1) 100%
            );
        }

        @include mq(lg) {
            min-height: 560px;
        }
    }

    &--investors {
        $text-max-width: 817px; // as defined per figma file
        --prefooter-bg: url('/static/img/prefooter-bg-investors.jpg');
        background-position: center center;
        background-size: cover;
        min-height: auto;
        text-align: left;

        #{$p} {
            &__inner {
                padding-top: 2.5rem;
                padding-bottom: 2.5rem;

                @include mq(lg) {
                    padding-top: 3.75rem;
                    padding-bottom: 3.75rem;
                }
            }

            &__title {
                font-size: 1.625rem;
                line-height: 1.4;
                margin-bottom: 0;
                max-width: $text-max-width;

                @include mq(md) {
                    font-size: 1.8rem;
                }

                @include mq(lg) {
                    font-size: 2.1875rem;
                }
            }

            &__text {
                font-size: $font-size-base;
                margin-top: .25rem;
                max-width: $text-max-width;

                @include mq(md) {
                    font-size: 1.125rem;
                }
            }
        }

        .btn {
            @extend .btn--primary.btn--inverted;
            color: $gray-darkest;
            margin-top: 2.5rem;
        }
    }

    &__inner {
        position: relative;
        z-index: 2;
        padding-top: $prefooter-spacing-y;
        padding-bottom: $prefooter-spacing-y;

        @include mq(lg) {
            padding-top: $prefooter-spacing-y * 2;
            padding-bottom: $prefooter-spacing-y * 2;
        }
    }

    &__title {
        margin-bottom: 3rem;
    }
}
