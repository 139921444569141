.specs {
    text-align: left;

    &__item {
        display: flex;
        border-bottom: 1px solid $gray-light;
    }
    &__property,
    &__content {
        padding: 1rem 1rem 1rem 0;
        margin: 0;
    }

    &__property {
        flex: 0 0 40%;
        max-width: 40%;
    }
    &__content {
        flex: 0 0 60%;
        max-width: 60%;
        color: $gray;
    }

    @include mq(md) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 3rem;

        &__property,
        &__content {
            font-size: 1.125rem;
            padding: 2rem 2rem 2rem 0;
        }
    }
}
