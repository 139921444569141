// Base Class
.testimonial {
    &__logo {
        margin-bottom: 2rem;
    }
    &__quote {
        line-height: 1.75;
    }
    &__author {
        cite {
            font-style: normal;
        }
        &::before {
            content: '—';
        }
    }
    &__image {
        width: 100%;
        height: auto;
    }
    &__more {
        margin-top: 2rem;
    }
}

// Reference Variant
.testimonial--reference {
    .testimonial__author {
        color: lighten($gray, 20%);
    }
}

// Featured Reference Variant
.testimonial--featured {
    display: grid;
    gap: 2rem;

    @include mq(lg) {
        grid-template-columns: 1fr 1fr;
        gap: 5rem;

        .testimonial__body {
            font-size: 1.125rem;
        }
        .testimonial__quote {
            line-height: 1.944444444;
        }
    }
}

// Featured, and also in and odd section, so layout is reversed
.testimonial--featured.testimonial--reversed {
    @include mq(lg) {
        .testimonial__body {
            order: -1;
        }
    }
}

// Slider Variant
.testimonial--slide {
    max-width: 920px;
    margin-left: auto;
    margin-right: auto;

    .testimonial {
        &__content {
            font-size: 1.125rem;
            color: $gray;
            position: relative;

            &::before {
                content: '';
                display: block;
                margin-bottom: 1rem;
                width: 46px;
                height: 29px;
                background-repeat: no-repeat;
                background-size: contain;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='46' height='29'%3E%3Cpath fill='%23dcdee1' d='M35.57 0a10.455 10.455 0 00-10.555 10.436 10.617 10.617 0 0010.742 10.44c.059 0 .148-.008.207-.009a13.724 13.724 0 01-8.467 2.909 2.634 2.634 0 00-2.662 2.61 2.438 2.438 0 002.424 2.61A18.587 18.587 0 0046 10.436 10.345 10.345 0 0035.57 0zM10.75 0A10.461 10.461 0 00.187 10.436a10.613 10.613 0 0010.739 10.44c.059 0 .146-.008.2-.009a13.727 13.727 0 01-8.468 2.909A2.635 2.635 0 000 26.39 2.45 2.45 0 002.439 29a18.6 18.6 0 0018.756-18.564A10.358 10.358 0 0010.75 0z'/%3E%3C/svg%3E");
            }
        }
        &__logo {
            max-width: 170px;
            img {
                height: auto;
            }
        }
        &__quote {
            line-height: 1.944444444;
            font-style: italic;
        }

        @include mq(md) {
            &__body {
                display: flex;
            }

            &__logo {
                flex: 0 0 200px;
                max-width: 200px;
            }

            &__content {
                flex: 1 0 0%;
                padding-left: 120px;

                &::before {
                    position: absolute;
                    top: 0;
                    left: 50px;
                }
            }
        }
    }
}
