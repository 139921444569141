:root {
    --color-primary: #{$brand-blue};
    --color-primary-offset: #{$brand-blue-light};
    --border-radius: 5px;
    --border-radius-large: 15px;
}

[data-theme='b2c'] {
    --color-primary: #{$brand-orange};
    --color-primary-offset: #{$brand-orange-light};
    --border-radius: 3px;
    --border-radius-large: 0px;
}
