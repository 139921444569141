.footer {
    background-color: $gray-darkest;
    color: #fff;

    a {
        color: #fff;
        opacity: 0.6;

        @include hover-focus {
            opacity: 1;
        }
    }

    &__upper,
    &__lower {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }

    &__upper {
        @include mq(md) {
            padding-top: 4rem;
            padding-bottom: 4rem;
        }
    }
    &__lower {
        text-align: center;
        font-size: 0.875rem;

        a {
            text-decoration: underline;
            @include hover-focus {
                text-decoration: none;
            }
        }
    }

    &__grid {
        display: grid;
        width: 100%;
        gap: 2rem;
        grid-template-columns: 1fr;

        @include mq(md) {
            grid-template-columns: repeat(12, 1fr);
        }
    }

    &__col {
        grid-column: 1 / -1;

        @include mq(md) {
            grid-column: span 6;
        }

        @include mq(lg) {
            grid-column: span 2;

            &--more {
                grid-column: span 3;
            }

            &--wide {
                grid-column: span 4;
            }

            &--extra-wide {
                grid-column: span 5;
            }
        }
    }

    &__social {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        font-size: 1.5rem;
        transform: translateY(-0.5rem);
    }
    &__sociallink {
        display: block;
        margin-right: 1rem;

        &--youtube {
            font-size: 1.65rem;
            transform: translateY(7%);
        }
    }
    &__label {
        font-family: $font-family-base;
        font-weight: 600;
        font-size: 1rem;
        margin-bottom: 1rem;
    }
    &__menuitem {
        margin-bottom: 0.5em;
    }
    &__link {
        display: block;
    }
    &__address {
        margin-bottom: 1rem;
        line-height: 1.875;
        margin-top: -2px; // optical alignment
    }
    &__phone,
    &__email {
        margin-bottom: 0.75rem;
    }
}

// Helpdesk Variation
.footer--helpdesk {
    color: $body-color;
    background-color: $gray-lightest;
    padding: 1.5rem 0;
    text-align: center;
    font-size: 0.875rem;

    a {
        color: $body-color;
        opacity: 1;
        text-decoration: underline;

        @include hover-focus {
            text-decoration: none;
        }
    }
}

.footer--investors {
    .logo {
        display: block;
        width: 150px;

        @include mq(md) {
            width: auto;
        }

        path {
            fill: currentcolor;
        }
    }

    .footer {
        &__upper {
            padding-bottom: 0;
        }

        &__grid {
            align-items: flex-end;
        }

        &__email,
        &__phone {
            a {
                display: flex;
                align-items: center;
                gap: .5rem;
            }
        }

        &__email {
            margin-bottom: 0;
            line-height: 2;
        }

        &__address {
            margin-bottom: 0;
            line-height: 2;
        }
    }
}
