body {
    --plyr-color-main: var(--color-primary);
    --plyr-video-background: transparent;
}

.plyr--video {
    border-radius: var(--border-radius-large);

    @include mq-down(md) {
        iframe {
            pointer-events: none !important;
        }
    }
}

@import '~plyr/src/sass/plyr.scss';
