.splitscreen {
    position: relative;
    min-height: 100vh;
    display: block;

    &__nav {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        color: #fff;
        position: absolute;
        top: 0;
        right: 0;
        padding: 1rem;
        padding-left: 0 !important;
        z-index: z('header');

        a {
            display: inline-block;
            font-size: 0.875rem;
            color: inherit;
            text-decoration: underline;
            margin-right: 1.5rem;

            @include hover-focus {
                text-decoration: none;
            }
        }
    }

    &__section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        min-height: 50vh;
        padding: 4.5rem 1rem;

        &--b2c {
            color: #fff;
            background-color: $gray-darkest;
        }
        &--b2b {
            color: $gray-darkest;
            background-color: #fff;
        }
    }

    &__inner {
        position: relative;
        z-index: 2;
        max-width: 500px;
        margin: auto;
    }

    &__bg {
        @include coverall;
        pointer-events: none;
        z-index: 1;
        overflow: hidden;

        img {
            opacity: 0;
            transform: scale(1.1);
            transition: opacity 0.3s ease, transform 0.4s ease-out;
        }
    }

    &__logo {
        display: block;
        margin-bottom: 2rem;
    }

    &__title {
        font-weight: 600;
        margin-bottom: 1rem;
    }
    &__description {
        line-height: 1.75;
        margin-bottom: 2.5rem;
    }

    @include mq(lg) {
        display: flex;

        &__section {
            flex: 0 0 50%;
            width: 50%;
            min-height: 0;
            padding: 2rem;
        }

        &__nav {
            color: $gray;
            padding: 1rem 3rem;
        }

        &__description {
            margin-bottom: 3.125rem;
        }
    }
}

// Hover Effect
.splitscreen {
    &__section:hover,
    &__section:focus-within {
        .splitscreen__bg img {
            opacity: 0.3;
            transform: scale(1);
        }
    }
}

// Themeing
.splitscreen {
    &__section--b2c {
        // Ultimately, this will be done with a custom property.
        // for now this is just this one button, so we'll override it.

        .btn--primary {
            outline-color: $brand-orange;
            background-color: $brand-orange;
            box-shadow: 0px 2px 20px rgba($brand-orange, 0.2);

            @include hover-focus {
                background-color: $brand-orange-light;
            }
        }
    }

    // offset the different logo heights.
    // b2b logo is 20px shorter in height, so we'll fix that with margin.
    &__section--b2b {
        @include mq(lg) {
            .splitscreen__logo {
                margin-top: 10px;
                margin-bottom: calc(2rem + 10px);
            }
        }
    }
}
