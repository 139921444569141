.postlist {
    margin: 0 auto;
    max-width: 810px;

    &__grid {
        display: grid;
        gap: 30px;
    }

    &__item {
        display: flex;
        flex-direction: column;
    }

    &__filternav {
        margin-bottom: 2rem;
    }

    &__filter {
        display: inline-block;
        color: $body-color;
        background-color: $gray-light;
        padding: 0.5rem 1rem;
        border-radius: 10em;

        &:not(:last-child) {
            margin-right: 0.5rem;
        }

        &:hover {
            color: $body-color;
            background-color: darken($gray-light, 6%);
        }

        &--active {
            color: #fff !important;
            background-color: var(--color-primary) !important;
        }
    }

    @include mq(md) {
        &__grid {
            grid-template-columns: 1fr 1fr;
        }
    }
    @include mq(xl) {
        max-width: none;

        &__grid {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }
}
