.app-download {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 2rem;
    text-align: center;

    &__title {
        font-size: 1rem;
        font-weight: 600;
        font-family: $font-family-base;
        margin-bottom: 2rem;
    }
    &__icon {
        width: 110px;
        height: 110px;
        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
        border-radius: 30px;
        overflow: hidden;
        margin-bottom: 1.5rem;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__linklist {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    &__item {
        flex-basis: 100%;

        @include mq(md) {
            flex: 0 0 auto;
        }
    }
    &__link {
        display: block;
        margin: 0.5rem;

        img {
            margin: 0 auto;
        }
    }
}
