body.no-scroll {
    overflow: hidden;
}

.modal,
.pyc-modal {
    z-index: z('modal');
    background-color: #fff;
    padding: 2rem;
    text-align: center;
    width: 90%;
    max-width: 960px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: $box-shadow-elevated;
    border-radius: var(--border-radius-large);

    &__overlay {
        position: fixed;
        z-index: z('modal') - 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.65);
    }

    &__title {
        margin-bottom: 1rem;
    }

    &__content {
        min-height: 100px;
    }

    &__close {
        @include button-reset;
        outline: 0;
        display: block;
        width: 3rem;
        height: 3rem;
        font-size: 1.25rem;
        position: absolute;
        top: 0;
        right: 0;

        &:hover {
            color: var(--color-primary);
        }
    }
}

.modal--video,
.pyc-modal--video {
    padding: 0;
    background-color: transparent;
    border-radius: 0;

    .modal__close,
    .pyc-modal__close {
        top: -3rem;
        color: #fff;

        @include mq(lg) {
            right: -3rem;
        }
        @include mq(xl) {
            right: -4rem;
            top: -4rem;
        }
    }
}

.modal--iframe,
.pyc-modal--iframe {
    .modal__content,
    .pyc-modal__content {
        position: relative;
        height: 75vh;

        @include mq(md) {
            max-height: 480px;
        }

        > iframe {
            @include coverall;
            width: 100%;
            height: 100%;
            overflow: hidden;
            outline: 0;
        }
    }
}

.modal--small,
.pyc-modal--small {
    max-width: 640px;
}

// Fade In Effect

.fade-in .modal,
.fade-in .pyc-modal {
    transform: translate(-50%, -65%);
    transition: transform 0.25s ease;

    &__overlay {
        opacity: 0;
        transition: opacity 0.4s linear;
    }
}

.fade-in.is-active .modal,
.fade-in.is-active .pyc-modal {
    transform: translate(-50%, -50%);

    &__overlay {
        opacity: 1;
    }
}
