.articlelist {
    &__item {
        border-bottom: solid 1px $gray-light;
    }

    &__link {
        color: $body-color;
        padding: 1rem 0;
        display: block;

        @include hover-focus {
            color: $body-color;
            text-decoration: underline;
        }
    }
}

.articlelist--sidebar {
    .articlelist__item {
        border: none;
        margin-bottom: 0.5rem;
    }
    .articlelist__link {
        padding: 0.75rem 0.625rem;
        border-radius: var(--border-radius);

        &--active {
            color: #fff !important;
            background-color: $body-color !important;
            text-decoration: none !important;
        }

        @include hover-focus {
            background-color: $gray-lighter;
            text-decoration: none;
        }
    }
}
