.langselect {
    &__label {
        @include flex-center;
        padding: 0.5rem;
        margin: 0;
        position: relative;
        overflow: hidden;

        @include hover-focus {
            color: var(--color-primary);
        }
    }
    &__input {
        @include coverall;
        cursor: pointer;
        width: 100%;
        height: 100%;
        min-width: 100px;
        opacity: 0;
        padding-right: 8px;
        padding-left: 8px;
        -webkit-appearance: none;
    }
}
