.cardlist {
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(auto-fit, minmax(300px, 379px));
    gap: 30px;

    &__item {
        display: flex;
        flex-direction: column;
    }
}
