// SUBMENU STYLES
$subnav-item-gap: 3.125rem; //50px

.subnav {
    display: none;
    flex-basis: 100%;
    position: relative;

    &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 1rem;
        right: 1rem;
        border-top: 1px solid $gray-light;
    }

    .is-open > & {
        display: block;
    }

    &__menu {
    }
    &__item {
    }
    &__link {
        display: block;
        color: $body-color;
        padding: 1.125rem 1rem;

        @include hover-focus {
            color: $body-color;
            background-color: $gray-lightest;
        }
    }
    &__desc {
        margin-top: 0.25rem;
        font-size: 0.75rem;
        font-weight: 400;
        line-height: 1.5;
    }

    @include mq(lg) {
        position: absolute;
        top: 100%;
        min-width: 180px;

        background-color: #fff;
        box-shadow: 0px 2px 10px rgba(#000, 0.1);
        border-radius: var(--border-radius);

        transform: translateY(-3px);

        &::before {
            display: none;
        }

        &__menu {
            padding: 0.5rem 0;
            border-radius: var(--border-radius);
            overflow: hidden;
        }
        &__link {
            padding: 0.5rem 1rem;
        }
        &__desc {
            // @include hyphenate;
            min-width: 240px;
        }
    }
}
