#crm-widget-iframe {
    height: 1350px; //magic number: should match the initial height of the pipedrive iframe content on mobile.

    @include mq(md) {
        height: 750px;
    }
}

.newsletter-modal-content {
    text-align: left;

    > * + * {
        margin-top: 1rem;
    }

    @include mq(md) {
        text-align: center;

        .streamfield {
            text-align: center;
        }

        .btn--block {
            display: inline-block;
            width: auto;
        }
    }
}

.logowall img {
    display: block;
    width: 100%;
    height: auto;
}
