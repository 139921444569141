.accordion {
    text-align: left;
    font-size: 1.125rem;

    &__item {
        border-radius: 0.5rem;
        background-color: #fff;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);

        &:not(:last-child) {
            margin-bottom: 0.625rem; //!0px
        }
    }
    &__target {
        position: relative;
    }
    &__header {
        @include button-reset;
        display: block;
        width: 100%;
        font-weight: 600;
        padding: 1.1875rem 4rem 1.125rem 1.5rem;
        text-align: left;
        position: relative;
    }
    &__panel {
        max-height: 75vh;
        overflow: hidden;
        margin-bottom: 0;
        color: $gray;
        line-height: 1.75;
        visibility: hidden;

        &__inner {
            padding: 0 4rem 1rem 1.5rem;

            > * + * {
                margin-top: 1rem;
            }
        }
    }
    &__indicator {
        display: block;
        position: absolute;
        color: var(--color-primary);
        top: 1.1875rem;
        right: 1.5rem;
        transform: translate(2px, -2px);

        &--closed {
            display: none;
        }
    }
}

.accordion--initialized {
    .accordion__panel {
        visibility: visible;
        transition: max-height 0.15s $animation-curve-default;
    }
}

.accordion__item--closed {
    .accordion__panel {
        max-height: 0 !important;
    }
    .accordion__indicator--open {
        display: none;
    }
    .accordion__indicator--closed {
        display: block;
    }
}
