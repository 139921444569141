// Header Component

.header {
    --header-logo-color: #000;
    --header-text-color: #{$body-color};

    display: flex;
    flex-direction: column;
    justify-content: center;
    color: var(--header-text-color);
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: z('header');
    transition: transform 0.25s $animation-curve-default,
        box-shadow 0.25s linear, background-color 0.15s linear;

    &--hidden {
        transform: translateY(-100%);
    }

    &--scrolled,
    &--active {
        --header-logo-color: #000 !important;
        --header-text-color: #{$body-color} !important;

        background-color: #fff !important;
        box-shadow: 0 2px 12px 0px rgba(36, 50, 66, 0.075);
    }

    &--active {
        transition: none;
    }

    &--helpdesk {
        position: relative !important;
        transform: none !important;
        box-shadow: none;
        border-bottom: 1px solid $gray-lighter;
    }

    &__banner {
        color: #fff;
        background-color: $gray-darkest;
        padding-top: 1rem;
        padding-bottom: 1rem;
        text-align: center;

        a {
            color: var(--color-primary-offset);
            text-decoration: underline;
        }
    }

    &__inner {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: $header-height;
        align-items: center;
        padding: 0 $spacing-x;
    }

    &__brand {
        display: flex;
        flex-shrink: 0;
        align-items: center;
        color: inherit;
        color: var(--header-logo-color);

        @include hover-focus {
            color: var(--header-logo-color);
        }
    }

    &__brand-suffix {
        display: block;
        font-size: 0.8125rem; //13px
        margin-left: 0.75rem;
        padding-left: 0.75rem;
        border-left: solid 1px $gray-darkest;
        font-weight: 600;
    }

    @include mq(md) {
        &__brand-suffix {
            font-size: 1.125rem;
            margin-left: 1rem;
            padding-left: 1rem;
        }
    }

    @include mq(lg) {
        &__left {
            padding-right: 1.5rem;
        }
        &__right {
            flex: 1 0 auto;
        }
    }

    @include mq(xl) {
        &__inner {
            justify-content: flex-start;
        }
        &__left {
            padding-right: 2rem;
        }
        &__right {
            flex: 1 0 auto;
        }
    }

    @include mq-down(lg) {
        &__inner {
            max-width: none;
        }
    }
}
