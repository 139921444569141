.responsiveimage {
    @include coverall;
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    object-position: center;

    // Lazyloading Blur-Up Styles
    &.js-lazyload,
    &.lazyloading {
        filter: blur(10px);
        transition: filter 1000ms;
    }

    &.lazyloaded {
        filter: blur(0);
    }

    &--align-top {
        object-position: center top;
    }
    &--align-bottom {
        object-position: center bottom;
    }
}
