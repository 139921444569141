.categoryindex {
    padding-bottom: 5rem;

    &__header {
        margin-bottom: 3rem;
    }
    &__title {
        font-size: 1.625rem; //26px
        line-height: 1.346153846; //35px
        margin-bottom: 0.25rem;
    }
    &__description {
        color: $gray;
    }
    &__grid {
        display: grid;
        grid-row-gap: 3rem;
    }

    @include mq(md) {
        margin-top: 3rem;
    }

    @include mq(lg) {
        &__grid {
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 6rem;
        }
    }
}
