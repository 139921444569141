//--------------------
// SCSS VARIABLES
//--------------------

// Grid breakpoints
//
// Define the minimum and maximum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
    xs: 0,
    md: 768px,
    lg: 992px,
    xl: 1260px
);

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
    lg: 960px,
    xl: 1230px
);

// Z-Index Stack
//
// Control the order of layers in the application
$z-layers: (
    'modal': 2000,
    'messages': 200,
    'header': 50,
    'content-overlay': 25
);

// Colors
//
// Grayscale.

$gray-darkest: #1f1b24;
$gray-darker: #323232;
$gray-dark: #636264;
$gray: #606060;
$gray-light: #dcdee1;
$gray-lighter: #edeff2;
$gray-lightest: #fafafa;

// Brand Colors

$brand-blue: #2f74f2;
$brand-blue-light: #4888ff;
$brand-orange: #f29000;
$brand-orange-light: #fda11a;

// State Colors

$state-success: #28a745;
$state-info: #498cbd;
$state-warning: #df8a13;
$state-error: #ff5252;

//Color Mappings

$brand-primary: $brand-blue;
$brand-primary-offset: $brand-blue-light;

// Links
//
// Style anchor elements.

$link-decoration: none;
$link-hover-decoration: none;

// Body
//
// Settings for the `<body>` element.

$body-bg: #fff;
$body-color: $gray-darkest;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-family-system: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';

$font-family-display: 'soleil', sans-serif;
$font-family-body: 'Open Sans';

$font-family-base: $font-family-body, $font-family-system;

$font-weight-base: normal;
$font-size-base: 1rem;
$line-height-base: 1.5;

// Animation
//
// Default easing curves, Transitions, etc

$animation-curve-fast-out-slow-in: cubic-bezier(0.4, 0, 0.2, 1);
$animation-curve-linear-out-slow-in: cubic-bezier(0, 0, 0.2, 1);
$animation-curve-fast-out-linear-in: cubic-bezier(0.4, 0, 1, 1);
$animation-curve-default: $animation-curve-fast-out-slow-in;

// Misc Shared
//
// common shared styles

$spacing-x: 1rem;
$spacing-y: 1rem;

$box-shadow-grounded: 0 2px 5px rgba(0, 0, 0, 0.05);
$box-shadow-elevated: 0px 10px 30px rgba(0, 0, 0, 0.1);

// Components
//
// Variables related to specific elements

$header-height: 70px;
$logobar-height-lg: 100px;
