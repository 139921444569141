.flag {
    display: inline-block;
    width: 1.5rem;
    height: 1.125rem;

    margin-left: 0.5rem;

    border-radius: var(--border-radius);

    &--de {
        @include flag-de();
    }
    &--at {
        @include flag-at();
    }
}