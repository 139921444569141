.offer {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    padding: 3.125rem 2rem;

    color: $body-color;
    background-color: #fff;
    border-radius: var(--border-radius-large);
    box-shadow: $box-shadow-elevated;

    // nice top border
    &:before {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        content: ' ';
        height: 8px;
        background: var(--color-primary);
        border-top-left-radius: var(--border-radius-large);
        border-top-right-radius: var(--border-radius-large);
        border: 1px solid #fff;
    }

    a:not(.btn) {
        text-decoration: underline;

        @include hover-focus {
            text-decoration: none;
        }
    }

    &__title {
        margin-bottom: 1rem;
    }
    &__description {
        font-size: 1.125rem;
        opacity: 0.8;
    }
    &__body {
        flex: 1 0 auto;
        padding: 3.125rem 0;

        .richtext {
            font-size: 1rem;
        }
        h2,
        h3,
        h4 {
            font-size: 1em;
            font-family: $font-family-base;
            font-weight: 700;
        }
        small {
            display: block;
            opacity: 0.6;
        }
    }
    &__actions {
        text-align: center;
    }
    &__more {
        font-size: 1.125rem;
        padding-top: 0.5rem;
        display: block;
        color: $gray;

        a {
            color: $gray;
        }
    }
}

.offer--highlighted {
    color: #fff;

    background: var(--color-primary) url('/static/img/shine-white.svg')
        no-repeat -1rem 0;
    background-size: 110% 60%;

    .richtext ul li::before {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='%23ffffff' d='M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z'/%3E%3C/svg%3E%0A");
    }
    a:not(.btn) {
        color: #fff;
    }
    // dont do the top border thing.
    &:before {
        content: '';
        height: 0;
        border: 0;
    }

    .offer__more {
        color: #fff;
    }
}
