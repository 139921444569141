//--------------------
// SCSS MIXINS
//--------------------

// shorthand for media-breakpoint-up
@mixin mq($name, $breakpoints: $grid-breakpoints) {
    $min: breakpoint-min($name, $breakpoints);
    @if $min {
        @media (min-width: $min) {
            @content;
        }
    } @else {
        @content;
    }
}

// Custom MQ for max-widths.
// applies to all widths below the given breakpoint
// i.e. mq-down(md, (md: 768px)) = max-width: 767px
@mixin mq-down($name, $breakpoints: $grid-breakpoints) {
    $min: breakpoint-min($name, $breakpoints);
    @if $min {
        @media (max-width: ($min - 1px)) {
            @content;
        }
    } @else {
        @content;
    }
}

// make container sizes
@mixin make-container-max-widths(
    $max-widths: $container-max-widths,
    $breakpoints: $grid-breakpoints
) {
    @each $breakpoint, $container-max-width in $max-widths {
        @include mq($breakpoint, $breakpoints) {
            max-width: $container-max-width;
        }
    }
}

//Hover
@mixin hover() {
    &:hover {
        @content;
    }
}

//Hover-Focus
@mixin hover-focus() {
    &:hover,
    &:focus {
        @content;
    }
}

// Only display content to screen readers
@mixin sr-only() {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px; // Fix for https://github.com/twbs/bootstrap/issues/25686
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}

// Use in conjunction with .sr-only to only display content when it's focused.
@mixin sr-only-focusable() {
    &:active,
    &:focus {
        position: static;
        width: auto;
        height: auto;
        overflow: visible;
        clip: auto;
        white-space: normal;
    }
}

// Text Hyphenation
@mixin hyphenate() {
    word-wrap: break-word;
    overflow-wrap: break-word;
    hyphens: auto;
}

// Text Truncation
@mixin truncate() {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@mixin flex-center() {
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin coverall() {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

@mixin scrollable() {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

@mixin list-reset() {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

@mixin link-reset() {
    color: inherit !important;
    text-decoration: none;
}

@mixin button-reset() {
    border: 0;
    padding: 0;
    background-color: transparent;
}

@mixin font-size-xl() {
    font-size: 2.1875rem; //35px

    @include mq(md) {
        font-size: 3.125rem; //50px
    }
}

// Create several lines of various height
// @args[0]: Color of the first line
// @args[1]: Height of the first line
// @args[2]: Color of the second line
// @args[3]: Height of the second line
// ...
// @args[(n-1)*2]: Color of the nth line
// @args[(n-1)*2 + 1]: Width of the nth line
@mixin lines($args...) {
    background-image: lines($args...);
}

// Two lines of equal height
// @args top: Color of top line
// @args bottom: Color of bottom line
@mixin two-lines($top, $bottom) {
    background-image: two-lines($top, $bottom);
}

// Three lines of equal height
// @args top: Color of top line
// @args center: Color of center line
// @args bottom: Color of bottom line
@mixin three-lines($top, $center, $bottom) {
    background-image: three-lines($top, $center, $bottom);
}

// Germany
// http://www.crwflags.com/fotw/flags/de.html
// https://en.wikipedia.org/wiki/Flag_of_Germany
// https://github.com/pixelastic/css-flags/blob/master/app/styles/_flags/deu.scss
@mixin flag-de() {
    $black: #000;
    $red: #ff0000;
    $yellow: #ffcc00;

    @include three-lines($black, $red, $yellow);
}

// Austria
// http://www.crwflags.com/fotw/flags/de.html
// https://en.wikipedia.org/wiki/Flag_of_Germany
// https://github.com/pixelastic/css-flags/blob/master/app/styles/_flags/deu.scss
@mixin flag-at() {
    $red: #ed2939;
    $white: #fff;

    @include three-lines($red, $white, $red);
}
