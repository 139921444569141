.logo {
    width: 115px;
    max-width: 100%;
    height: auto;
    fill: currentColor;

    @include mq(md) {
        width: 135px;
    }
}
