.avatar {
    display: block;
    width: 5rem;
    height: 5rem;
    padding: 3px;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        border-radius: 50%;
    }
}
