.js-focus-visible :focus:not(.focus-visible) {
    outline: none;
}

.js-focus-visible .focus-visible {
    outline: 5px solid var(--color-primary);
}

// Work around a Firefox/IE bug where the transparent `button` background
// results in a loss of the default `button` focus styles.
//
// Credit: https://github.com/suitcss/base/
button:focus {
    outline: none;
}

//
// Improve readability of focused elements when they are also in an
// active/hover state.
//

a {
    &:active,
    &:hover {
        outline: none;
    }
}

// Suppress the focus outline on elements that cannot be accessed via keyboard.
// This prevents an unwanted focus outline from appearing around elements that
// might still respond to pointer events.
//
// Credit: https://github.com/suitcss/base
[tabindex='-1']:focus {
    outline: none !important;
}
