// Main Site Layout

body {
    overflow-x: hidden;
    height: 100%;

    &.no-scroll {
        overflow: hidden;
    }
    &.no-scroll-mobile {
        @include mq-down(lg) {
            overflow: hidden;
        }
    }
    &.is-blurred .layout {
        filter: blur(4px);
    }
}

// Default Layout

.layout {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    min-height: 100vh;
    position: relative;

    .header,
    .footer {
        flex: none;
    }

    .main {
        display: flex;
        flex-direction: column;
        flex: 1 0 auto;

        &::after {
            content: '';
            display: block;
            height: 0px;
            visibility: hidden;
        }
    }
}

.layout--fixed-header {
    .main {
        padding-top: $header-height;
    }
}

.layout--home {
    .header {
        --header-logo-color: #fff;
        --header-text-color: #fff;
        background-color: transparent;
    }
}

.layout--investors {
    --flow-spacing: clamp(3.75rem, 10vw, 8.75rem); // 60px on mobile as per figma file, 140px on desktop as per Request

    .section {
        margin-top: var(--flow-spacing);
    }

    .prefooter {
        margin-top: var(--flow-spacing);
    }

    @include mq-down(lg) {
        .header {
            &:not(.header--scrolled) {
                background-color: transparent;

                .logo {
                    color: #fff;
                }

                .nav__language {
                    color: #fff;
                }
            }

            .nav__language {
                display: block;
                margin-right: 0;
                padding-right: 0;
                border: 0;
            }
        }
    }
}

.container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: $spacing-x;
    padding-right: $spacing-x;

    @include make-container-max-widths();

    &--narrow {
        max-width: 720px !important;
    }
}
