.article {
    --richtext-size-md: 1rem;
    padding-bottom: 5rem;

    @include mq(md) {
        padding-top: 2rem;
    }
    @include mq(lg) {
        display: grid;
        grid-gap: 4rem;
        grid-template-columns: 1fr 2fr;
    }
    @include mq(xl) {
        grid-template-columns: 300px 700px;
    }

    &__sidebar {
        margin-bottom: 2rem;
    }

    &__title {
        margin-bottom: 2.5rem;
    }

    &__content {
        border-bottom: solid 1px $gray-light;
        padding-bottom: 3.125rem;
        margin-bottom: 3.125rem;
    }

    &__related {
        padding-bottom: 3.125rem;
        p {
            margin-bottom: 0.5rem;
        }

        a {
            display: block;
            padding: 0.3rem 0;

            @include hover-focus {
                text-decoration: underline;
            }
        }
    }
}
