.card {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    position: relative;
    color: $body-color;
    background-color: #fff;
    border-radius: var(--border-radius-large);
    box-shadow: $box-shadow-elevated;

    &__meta {
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
        font-size: 0.875rem;
        font-weight: 400;
        color: $gray;
        margin-bottom: 0.25rem;

        & > * + *:not(.card__tags)::before {
            content: '·';
            display: inline-block;
            margin: 0 .5em;
        }
    }

    &__link {
        display: flex;
        flex-direction: column;
        flex: 1 0 auto;
        color: $body-color !important;
    }

    &__content {
        display: flex;
        flex-direction: column;
        flex: 1 0 auto;
        padding: 2rem;
    }

    &__title {
        margin-bottom: 1rem;
    }

    &__tags {
        width: 100%;

        > span {
            display: inline-block;
            margin-right: 0.5rem;
        }
    }

    &__desc {
        @include hyphenate;
        color: $gray;
        font-size: 1rem;
        line-height: 1.75; //28px
        flex: 1 0 auto;

        p:last-child {
            margin-bottom: 0;
        }
    }
    &__disclaimer {
        color: $gray;
    }
}

.card--default,
.card--post {
    .card__title {
        font-weight: 500;
        font-size: 1.4375rem; //23px
    }
    .card__image {
        position: relative;
        padding-top: 56.25%;
        background-color: $gray-light;
        border-radius: var(--border-radius-large) var(--border-radius-large) 0 0;
        overflow: hidden;

        img {
            @include coverall;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
    .card__logo {
        margin-bottom: 1rem;
    }
    .btn {
        justify-content: flex-start;
        text-align: left;
    }
}

.card--hardware {
    margin-top: 125px;
    text-align: center;

    .card__image {
        position: absolute;
        top: -125px;
        width: 100%;
        height: 250px;

        img {
            width: auto !important;
            height: 250px !important;
            object-fit: contain;
            object-position: center;
            margin: 0 auto;
        }
    }
    .card__content {
        margin-top: 125px;
    }
}

.card--helpdesk {
    .card__icon {
        color: var(--color-primary);
        font-size: 2.5rem;
        margin-bottom: 1rem;
    }
}

.card--features {
    .card__title {
        text-align: center;
        border-bottom: 1px solid $gray-light;
        padding-bottom: 1rem;
        font-size: 1.125rem;
        font-weight: 600;
    }
    .richtext {
        font-size: 1rem;
    }
}

.card--clickable {
    transform: translateY(0);
    transition: transform 0.2s ease, box-shadow 0.2s linear;
    will-change: transform;
}

.card--clickable:hover,
.card--clickable:focus-within {
    transform: translateY(-4px);
    box-shadow: 0px 12px 30px rgba(0, 0, 0, 0.17);
}
