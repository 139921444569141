// Main Typography Styles

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4 {
    font-family: $font-family-display;
    font-weight: 500;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
}

h1,
.h1 {
    font-size: 1.875rem; //30px
    line-height: 1.428;

    @include mq(md) {
        font-size: 2.625rem; //42px
        line-height: 1.3;
    }
}

h2,
.h2 {
    font-size: 1.625rem; //26px
    line-height: 1.346;

    @include mq(md) {
        font-size: 2.1875rem; //35px
        line-height: 1.428;
    }
}

h3,
.h3 {
    font-size: 1.3125rem; //21px
    line-height: 1.25;

    @include mq(md) {
        font-size: 1.625rem; //26px
        line-height: 1.346;
    }
}

// Lead Paragraph

.lead {
    font-size: 1.4375rem; //23px
    font-weight: 600;
}

// Hyphenation

.hyphenate {
    @include hyphenate;
}

// Truncation

.truncate {
    @include truncate;
}
