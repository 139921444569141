$logobar-column-gap: 2rem;

.logobar {
    $p: &;
    padding-top: 1rem;
    padding-bottom: 1rem;

    @include mq(lg) {
        min-height: $logobar-height-lg;
    }

    &__label {
        font-family: $font-family-body;
        font-size: .875rem;
        color: #050505; // defined as per figma file

        @include mq(md) {
            font-size: 1rem;
        }

        @include mq(lg) {
            font-size: 1.125rem;
        }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-left: -$logobar-column-gap/2;
        margin-right: -$logobar-column-gap/2;

        @include mq(md) {
            justify-content: center;
        }
        // @include mq(xl) {
        //     justify-content: space-between;
        // }
    }
    &__item {
        display: flex;
        justify-content: center;
        flex: 0 0 50%;
        max-width: 50%;
        padding: $logobar-column-gap/2;

        @include mq(md) {
            flex-basis: auto;
            max-width: 100%;
            padding: $logobar-column-gap/2 $logobar-column-gap;
        }
    }
    &__img {
        width: auto;
        height: auto !important;
        max-height: 24px;
        object-fit: contain;

        @include mq(xl) {
            max-height: 30px;
        }
    }

    &--investors {
        $spacing: 2rem;
        background-color: #fafafa; // defined as per figma file;
        min-height: auto;
        padding-top: $spacing;
        padding-bottom: $spacing;

        .container {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: $spacing;

            @include mq(md) {
                justify-content: flex-end;
            }
        }

        #{$p} {
            &__list {
                gap: $spacing;
                margin: 0;

                @include mq(md) {
                    justify-content: flex-end;
                }
            }

            &__item {
                padding: 0;
                flex: auto;
            }
        }
    }

    // implemented ryan mulligans idea: https://ryanmulligan.dev/blog/css-marquee/
    &--marquee {
        overflow: hidden;
        --gap: 5.625rem; // as per figma file
        display: flex;
        gap: var(--gap);

        #{$p} {
            &__list {
                margin: 0;
                flex-shrink: 0;
                gap: var(--gap);
                justify-content: space-around;
                min-width: 100%;
                animation: marquee 45s linear infinite;
            }

            &__item {
                padding: 0;
                flex: auto;
            }
        }

        @media (hover: hover) {
            &:where(:hover, :focus-within) {
                #{$p} {
                    &__list {
                        animation-play-state: paused;
                    }
                }
            }
        }
    }
}
