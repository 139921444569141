// Alert Component

.alert {
    position: relative;
    border-radius: 0.25rem;
    border-width: 1px;
    border-style: solid;
    padding: 1rem;

    .icon {
        margin-right: 0.5rem;
    }

    // Variations
    &--info {
        color: darken($state-info, 10%);
        background-color: lighten($state-info, 45%);
        border-color: lighten($state-info, 15%);

        .btn,
        .btn:hover,
        .btn:focus {
            background-color: darken($state-info, 10%);
        }
    }
    &--warning {
        color: darken($state-warning, 12%);
        border-color: lighten($state-warning, 25%);
        background-color: lighten($state-warning, 45%);

        .btn,
        .btn:hover,
        .btn:focus {
            background-color: darken($state-warning, 12%);
        }
    }
    &--success {
        color: darken($state-success, 15%);
        border-color: darken($state-success, 2%);
        background-color: lighten($state-success, 50%);

        .btn,
        .btn:hover,
        .btn:focus {
            background-color: darken($state-success, 10%);
        }
    }
    &--error {
        color: darken($state-error, 10%);
        border-color: darken($state-error, 5%);
        background-color: desaturate(lighten($state-error, 29%), 20%);

        .btn,
        .btn:hover,
        .btn:focus {
            background-color: darken($state-error, 10%);
        }
    }
}
