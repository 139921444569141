.nav {
    display: flex;
    align-items: center;

    &__container {
        flex: 1 0 auto;
    }

    &__menu {
        display: flex;
        font-weight: 600;
    }
    &__item {
        display: block;
        position: relative;
    }
    &__link {
        display: block;
        padding: 1.1875rem $spacing-x;
        color: var(--header-text-color, inherit);
        letter-spacing: 0.75px;
        font-weight: 600;

        @include hover-focus {
            color: var(--header-text-color, inherit);
        }
    }
    &__language {
        border-right: 1px solid $gray-light;
        padding-right: 0.5rem;
        color: var(--header-text-color, inherit);
    }
    &__toggle {
        @include button-reset;
        display: block;
        padding: 1rem;
        margin-right: -1rem;
    }
    &__subtoggle {
        @include button-reset;
        display: flex;
        width: 100%;
        padding: 1.125rem 1rem;
        color: var(--header-text-color, inherit);
        text-align: left;
        letter-spacing: 0.75px;

        &__text {
            display: block;
            flex: 1 0 auto;
        }

        &__indicator {
            display: block;
            position: relative;
            transform: rotate(0);
            transition: transform 0.25s $animation-curve-default;
        }

        // Clicked / Open State
        .nav__item--with-submenu.is-open & {
            .nav__subtoggle__indicator {
                transform: rotate(-180deg);
            }
        }
    }

    .btn {
        font-size: 0.875rem;
        padding-left: 1rem;
        padding-right: 1rem;
    }

    // MOBILE-ONLY STATE
    @include mq-down(lg) {
        &__container {
            @include scrollable;
            display: none;

            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            width: 100%;
            height: calc(100vh - #{$header-height});
            height: calc((var(--vh, 1vh) * 100) - #{$header-height});

            border-top: 1px solid $gray-light;
            background-color: #fff;
            box-shadow: $box-shadow-grounded;

            .is-open > & {
                display: block;
            }
        }
        &__menu {
            display: block;
        }
        &__item {
            border-bottom: 1px solid $gray-light;

            &--with-submenu.is-open {
                background-color: $gray-lightest;
            }
            &--desktop {
                display: none;
            }
        }
        &__subtoggle__indicator {
            right: -3px; // optical alignment
        }
        &__language {
            display: none;
            padding-right: $spacing-x;
            margin-right: 0.75rem;

            .is-open > & {
                display: block;
            }
        }
        &__additional {
            padding: 1.1875rem $spacing-x;

            &--helpdesk {
                padding-top: 4rem;
                text-align: center;
            }
        }
        &__cta--mobile {
            padding-right: 1rem;

            .is-open > & {
                display: none;
            }
        }
    }

    // DESKTOP-ONLY STATE
    @include mq(lg) {
        &__container,
        &__additional {
            font-size: 0.875rem;
            display: flex;
            align-items: center;
        }
        &__additional {
            margin-left: auto;

            > * + * {
                margin-left: $spacing-x/2;
                margin-right: 0 !important;
            }
        }
        &__menu {
            margin-right: auto;
        }
        &__link,
        &__subtoggle {
            padding: 0.75rem 0.25rem;

            @include hover-focus {
                text-decoration: underline;
            }
        }
        &__item {
            display: flex;
            margin-right: $spacing-x/2;

            &--with-submenu:hover,
            &--with-submenu.is-open {
                .subnav {
                    display: block;
                }
                .nav__subtoggle__text {
                    text-decoration: underline;
                }
            }
            &--mobile {
                display: none;
            }
        }
        &__toggle {
            display: none;
        }
        &__cta--mobile {
            display: none;
        }
    }

    @include mq(xl) {
        &__item {
            margin-right: $spacing-x;
        }
        &__additional > * + * {
            margin-left: $spacing-x;
        }
        &__language {
            padding-right: $spacing-x;
        }
    }
}
