.partner-network {
    position: relative;
    max-width: 900px;
    margin: 0 auto;

    &__image {
        width: 100%;
        height: auto;
    }
    &__center {
        position: absolute;
        width: 21%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    &__logo {
        width: 100%;
        height: auto;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
        border-radius: 50%;
        animation: pulse 1s infinite alternate;
    }
}
