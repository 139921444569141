$streamfield-block-spacing: 2rem;

.streamfield {
    text-align: left;
    font-weight: 400;
    line-height: 1.944444444;

    [class^='block-'] + [class^='block-'] {
        margin-top: $streamfield-block-spacing;
    }

    .block-teaser + .block-teaser, .block-logo_grid + .block-teaser {
        margin-top: 5rem;

        @include mq(lg) {
            margin-top: 10rem;
        }
    }

    .block-paragraph,
    .block-heading,
    .block-blockquote {
        @extend %richtext;
    }

    .block-image {
        img {
            max-width: 100%;
            height: auto;
            object-fit: cover;
            vertical-align: bottom;
            border-radius: var(--border-radius-large);
        }
        figcaption {
            font-size: 1rem;
            padding-top: 0.5rem;
            color: lighten($gray, 15%);
            font-style: italic;
            text-align: center;
        }
    }

    .block-heading {
        position: relative;

        h2,
        h3,
        h4 {
            scroll-margin-top: $header-height + 16px;
        }

        .heading-anchor {
            display: none;
        }

        @include mq(md) {
            padding-left: 2ch;
            margin-left: -2ch;

            .heading-anchor {
                position: absolute;
                left: -0.5ch;
            }
            &:hover .heading-anchor {
                display: inline-block;
            }
        }
    }

    .block-embed {
        > div {
            position: relative;
            height: 0;
            padding-bottom: 56.25% !important;
            overflow: hidden;
            border-radius: var(--border-radius-large);
            -webkit-mask-image: -webkit-radial-gradient(white, black);
            mask-image: -webkit-radial-gradient(white, black);

            > iframe {
                @include coverall;
                width: 100% !important;
                height: 100%;
                z-index: 1;
            }
        }
    }

    .block-call_to_action {
        text-align: center;
    }
}

// Variant that stretches image and video blocks a little outside the container
.streamfield--extrude-blocks {
    .block-image,
    .block-vimeo,
    .block-embed {
        @include mq(lg) {
            margin-left: -4rem;
            margin-right: -4rem;
        }
    }
}

