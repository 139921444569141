$teaser-column-gap: 6rem;

.teaser {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;

    &__col {
        flex-basis: 100%;

        &--image {
            margin-bottom: 3rem;
        }
    }

    &__img {
        width: 100%;
        height: auto;
        object-fit: cover;
        vertical-align: bottom;
        border-radius: var(--border-radius-large);
    }

    &__video {
        //empty
    }

    &__header {
        margin-bottom: 2rem;
    }

    &__title {
        margin-bottom: 0.5rem;
    }
    &__subtitle {
        font-weight: 400;
        @include mq-down(md) {
            font-size: 1.25rem; //20px
        }
    }

    &__content {
        color: $gray;

        &:not(:last-child) {
            margin-bottom: 2rem;
        }
    }

    @include mq(lg) {
        align-items: center;
        margin-left: -$teaser-column-gap/2;
        margin-right: -$teaser-column-gap/2;

        .streamfield > .block-teaser:nth-child(even) > &,
        &--reversed {
            flex-direction: row-reverse;
        }

        &__title {
            margin-bottom: 0;
        }

        &__col {
            padding: 0 $teaser-column-gap/2;
            flex-basis: 50%;
            max-width: 50%;

            &--image {
                margin-bottom: 0;
            }
        }
    }
}

.teaser-container--with-margin {
    margin-bottom: 5rem;

    @include mq(lg) {
        margin-bottom: 7.8125rem; //125px
    }
}
