.contactinfo {
    display: flex;
    flex-wrap: wrap;
    font-size: 1.125rem;
    font-weight: 600;
    text-align: center;
    justify-content: center;
    margin-top: -0.5rem;

    &__link {
        display: flex;
        align-items: center;
        padding: 0.5rem;
        margin: 0 2rem;

        @include hover-focus {
            text-decoration: underline;
        }

        .icon {
            margin-right: 0.5em;
        }
    }
}

.contactinfo--inverted {
    .contactinfo__link {
        color: #fff;
        outline-color: #fff;
    }
}

.contactinfo--outlined {
    border-radius: 10rem;
    padding: 1rem;
    background-color: #fff;
    box-shadow: $box-shadow-elevated;
    max-width: 640px;
    margin: 0 auto;
}
