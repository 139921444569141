// Form Component

form {
    .req {
        position: relative;
        top: -0.125em;
        line-height: 0.5em;
        padding-left: 0.125em;
        color: $state-error;
    }
}

.form {
    &__feedback,
    &__fields {
        width: 100%;
        margin-bottom: 2rem;
    }
    &__actions {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
    }
}

.form--newsletter {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .form__fields {
        margin: 0 0 1rem 0;
        flex-basis: 100%;
    }

    @include mq(md) {
        .form__fields {
            flex: 1 0 0%;
            margin: 0;
        }
        .form__actions {
            height: 90px; // alignment with form input
            padding: 1rem 0 1rem 1rem;
        }
    }
}

.formcontainer {
    margin: 0 auto;

    &--narrow {
        max-width: 450px;
    }
    &--medium {
        max-width: 600px;
    }
}
