$section-padding-y: 5rem;

.section {
    position: relative;
    scroll-margin-top: $header-height;

    &__inner {
        padding-top: $section-padding-y;
        padding-bottom: $section-padding-y;
        position: relative;
        z-index: 1;
    }

    &__header {
        margin-bottom: 3.125rem; // 50px on mobile
    }

    &__title {
        text-align: center;
        margin-bottom: 1rem;
    }

    &__intro {
        margin: 0 auto;
        max-width: 75ch;
        text-align: center;
        font-size: 1rem;
        line-height: 1.944444444;
        color: $gray;
    }

    &__cta {
        text-align: center;
    }

    &__video {
        max-width: 64rem; //1024
        margin: 0 auto;
        overflow: hidden;
        border-radius: var(--border-radius-large);
    }

    @include mq(md) {
        &__intro {
            font-size: 1.125rem; //18px
        }
    }

    @include mq(lg) {
        &__inner {
            padding-top: $section-padding-y * 2;
            padding-bottom: $section-padding-y * 2;
        }
        &__header {
            margin-bottom: $section-padding-y; // 80px on desktop
        }
    }
}

// Variations
.section--gray {
    background-color: $gray-lightest;
}

.section--black {
    background-color: $gray-darkest;
}

.section--centered {
    text-align: center;
}

.section--blue {
    color: #fff;
    background-color: $brand-blue;

    .section__inner {
        background: transparent url('/static/img/shine-white.svg') no-repeat 0 0;
        background-size: contain;
    }

    .section__intro {
        color: #fff;
    }
}

.section--curved {
    background-color: transparent;
    overflow: hidden;

    &::before,
    &::after {
        content: '';
        display: block;
        position: absolute;
        background-color: $brand-blue;
    }

    &::before {
        top: 0;
        left: 0;
        right: 0;
        bottom: 125px;
    }

    &::after {
        bottom: 125px;
        left: -250px;
        right: -250px;
        height: 125px;
        border-radius: 0 0 100% 100%;
        transform: translateY(99%);
    }

    &.section--overflow {
        &::before,
        &::after {
            bottom: 60%;
        }

        @include mq(md) {
            &::before,
            &::after {
                bottom: 50%;
            }
        }
    }

    &.section--overflow-fixed {
        &::before,
        &::after {
            bottom: auto;
        }

        &::before {
            height: 380px;
        }

        &::after {
            top: 380px;
            transform: translateY(-1px);
        }
    }
}

.section--imagebg {
    @include mq(md) {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: center;
        min-height: 800px;
    }

    .section__content {
        color: #fff;
        max-width: 550px;
    }
}

.section--no-padding {
    .section__inner {
        padding-top: 0;
        padding-bottom: 0;
        // left/right are not reset, because they are being set by .container, which is a class that is usually also on the same element as .section_inner
    }
}

.section--logo-banner {
    @extend .section--no-padding;

    .section__title {
        font-size: 1.25rem;
        font-weight: 400;

        @include mq(md) {
            font-size: 1.625rem;
        }
    }

    .logobar {
        @extend .section--gray;
        --logo-height: clamp(2rem, 4vw, 4rem);
        --gap: calc(var(--logo-height) * 1.5);
        padding-top: var(--logo-height);
        padding-block: var(--logo-height);

        &__img {
            @media (hover: hover) {
                max-height: none;
                height: var(--logo-height) !important;
            }
        }
    }
}

.section--investors-blocks {
    @extend .section--no-padding;

    &[data-block-type='label_value_list'] {
        @extend .section--gray;
        padding-top: 1.875rem;
        padding-bottom: 1.875rem;
    }

    &[data-block-type='teaser'] {
        .teaser {
            flex-direction: row-reverse;

            @include mq-down(md) {
                &__col {
                    &--image {
                        order: 2;
                        margin-bottom: 0;
                        margin-top: 3rem;
                    }

                    &--content {
                        order: 1;
                    }
                }
            }
        }

        & ~ &[data-block-type='teaser'] {
            &:nth-child(odd) .teaser {
                flex-direction: row;
            }

            &:nth-child(even) .teaser {
                flex-direction: row-reverse;
            }
        }
    }
}

.section--news {
    @extend .section--no-padding;

    .section {
        &__title {
            margin-bottom: 2.5rem;
        }
    }
}
