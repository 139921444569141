.slider {
    position: relative;
    width: 100%;

    &__track {
        overflow: hidden;
    }

    &__slides {
        position: relative;
        width: 100%;
        list-style: none;
        backface-visibility: hidden;
        transform-style: preserve-3d;
        touch-action: pan-Y;
        overflow: hidden;
        padding: 0;
        white-space: nowrap;
        display: flex;
        flex-wrap: nowrap;
        will-change: transform;

        a {
            user-select: none;
        }

        &--dragging {
            user-select: none;
        }
    }

    &__slide {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0;
        flex-shrink: 0;
        white-space: normal;
        user-select: none;
        -webkit-touch-callout: none;
        -webkit-tap-highlight-color: transparent;

        @include mq(lg) {
            padding: 0 3rem;
        }
    }

    &__arrows {
        display: none;
        -webkit-touch-callout: none;
        user-select: none;

        @include mq(lg) {
            display: block;
        }
    }

    &__arrow {
        @include flex-center;
        font-size: 3rem;
        border: 0;
        background-color: transparent;
        position: absolute;
        top: 0;
        transition: all 0.2s linear;
        color: $gray;
        opacity: 0.33;

        &:hover {
            color: var(--color-primary);
            opacity: 1;
        }

        &--left {
            left: -2rem;
        }
        &--right {
            right: -2rem;
        }
        &--disabled {
            opacity: 0;
            pointer-events: none;
        }
    }

    &__bullets {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        -webkit-touch-callout: none;
        user-select: none;
        margin-top: 1rem;
    }

    &__bullet {
        display: block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        border: 0;
        padding: 0;
        margin: 0 0.25rem;
        background-color: $gray-light;
        transition: all 0.1s linear;

        @include hover-focus {
            background-color: $gray;
        }

        &--active {
            background-color: var(--color-primary) !important;
        }
    }

    &--rtl {
        direction: rtl;
    }
}
