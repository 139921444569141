.imagelink {
    text-align: center;
    margin-bottom: 2.1875rem;

    @include mq(md) {
        margin-bottom: 1rem;
    }

    &__image {
        position: relative;
        height: 0;
        padding-bottom: 66.66666%;
        border-radius: var(--border-radius-large);
        border: 3px solid #fff;
        overflow: hidden;
        box-shadow: $box-shadow-elevated;

        img {
            @include coverall;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }

        a > & {
            transform: translateY(0);
            transition: transform 0.2s ease, box-shadow 0.2s linear;
            will-change: transform;
        }
        a:hover > &,
        a:focus > & {
            transform: translateY(-4px);
            box-shadow: 0px 12px 30px rgba(0, 0, 0, 0.17);
        }
    }
    &__title {
        color: $body-color;
        margin-top: 2rem;
        font-size: 1.4375rem; //23px
        font-weight: 600;
    }
    &__more {
        margin-top: 0.5rem;
    }
}
