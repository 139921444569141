.hero {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;

    &__inner {
        position: relative;
        z-index: 3;
        text-align: center;
        padding-top: 4rem;
        padding-bottom: 4rem;
    }

    &__title {
        @include font-size-xl;
        max-width: 60rem;
        margin: 0 auto 3rem;
    }
    &__subtitle {
        margin-top: -2rem;
        font-size: 1.125rem;
    }

    &__video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
}

// Background Image or Video Variant
.hero--with-bg {
    min-height: 580px;
    overflow: hidden;
    color: #fff;

    .hero__bg,
    .hero__bg::after,
    .hero__video {
        @include coverall;
    }

    .hero__bg {
        background-color: $gray-darkest;
        z-index: 0;

        // Dimmer Overlay
        &::after {
            content: '';
            display: block;
            z-index: 1;
            background-color: rgba(0, 0, 0, 0.5);
        }
    }
}

.hero--with-bg.hero--with-gradient {
    .hero__bg {
        &::before {
            content: '';
            display: block;
            position: absolute;
            z-index: 2;
            bottom: 0;
            left: 0;
            right: 0;
            height: 33%;
            background: linear-gradient(
                180deg,
                rgba($gray-darkest, 0) 0%,
                rgba($gray-darkest, 1) 100%
            );
        }
    }
}

.hero--investors {
    --logo-bar-height: 87px;
    height: calc(100vh - var(--logo-bar-height));
    .hero {
        &__inner {
            text-align: left;
        }
        &__title {
            margin-left: 0;
        }
    }

    .btn {
        @extend .btn--secondary.btn--inverted;
        background-color: transparent;
    }
}

// Larger Variant on Home
.layout--home .hero,
.layout--investors .hero {
    @include mq(lg) {
        height: calc(100vh - #{$logobar-height-lg});
    }
}
