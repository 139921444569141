//
// Screenreaders
//

.sr-only {
    @include sr-only();
}

.sr-only-focusable {
    @include sr-only-focusable();
}

.sr-skip-link {
    @include sr-only();
    @include sr-only-focusable();

    &:focus {
        position: absolute;
        z-index: 9999;
        left: 50%;
        top: 0;
        font-size: 1rem;
        transform: translateX(-50%);
        background-color: $gray-darkest;
        color: #fff;
        border-radius: 0 0 0.5rem 0.5rem;
        padding: 1rem 1.5rem;
        outline: 0;
        white-space: nowrap;
    }
}
