// Button Component

.btn {
    display: inline-block;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.375;
    padding: 0.75em 1.5em;
    border-radius: var(--border-radius);
    text-decoration: none;
    -webkit-appearance: none;
    letter-spacing: 1px;

    &:disabled,
    &--disabled {
        cursor: not-allowed;
        pointer-events: none;
        opacity: 0.65;
    }

    .icon {
        margin-left: .5rem;

        &--arrow-right {
            transition: transform 0.25s ease;
        }
    }

    @include hover-focus {
        .icon--arrow-right {
            transform: translateX(25%);
        }
    }
}

// Block Modifier for full-width
.btn--block {
    display: flex;
    width: 100%;

    & + & {
        margin-top: 1rem;
    }
}

// Primary CTA Buttons
.btn--primary {
    color: #fff;
    background-color: var(--color-primary);
    transition: color 0.25s linear, background-color 0.25s linear;
    box-shadow: 0px 2px 20px rgba(var(--color-primary), 0.2);

    @include hover-focus {
        color: #fff;
        background-color: var(--color-primary-offset);
    }

    &.btn--inverted {
        color: var(--color-primary);
        background-color: #fff;
        box-shadow: 0px 2px 20px rgba(#fff, 0.2);

        @include hover-focus {
            color: var(--color-primary);
            background-color: $gray-lighter;
        }
    }
}

.btn--secondary {
    color: $gray-darkest;
    border-color: $gray;
    background-color: transparent;
    transition: color 0.25s linear, background-color 0.25s linear,
        box-shadow 0.25s linear;

    @include hover-focus {
        color: var(--color-primary);
        border-color: var(--color-primary);
        box-shadow: 0 0 0 1px var(--color-primary);
    }

    &.btn--inverted {
        color: #fff;
        border-color: #fff;

        @include hover-focus {
            box-shadow: 0 0 0 1px #fff;
            background-color: rgba(#fff, 0.15);
        }
    }
}

.btn--link {
    @include button-reset;
    text-transform: uppercase;
    color: var(--color-primary);

    @include hover-focus {
        color: var(--color-primary-offset);
    }
}

.btn--muted {
    padding: 0;
    background-color: transparent;
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: 0;
    color: $gray-lighter;

    @include hover-focus {
        color: #fff;
    }
}

.btn--font-dark {
    color: $gray-darkest !important;
}

.btngroup .btn {
    margin: 0.5rem;
}
