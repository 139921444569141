.iconblocks {
    display: grid;
    gap: 2rem;

    @include mq(lg) {
        grid-template-columns: repeat(3, 1fr);
        gap: 4rem;
    }

    &__item {
        display: flex;
        flex-direction: column;
    }
    &__icon {
        font-size: 3rem;
        color: var(--color-primary);
        margin-bottom: 2rem;
    }
    &__title {
        // flex: 1 0 auto;
        margin-bottom: 1rem;
        font-family: $font-family-base;
        font-size: 1.4375rem; //23px
        font-weight: 600;
        letter-spacing: 0.15px;
        line-height: 1.52173913;
    }
    &__description {
        color: $gray;
        font-size: 1.125rem;
        line-height: 1.944444444;
    }
}
