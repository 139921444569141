$page-header-spacing: 5rem;

.page {
    &__header {
        padding-top: $page-header-spacing;
        padding-bottom: $page-header-spacing;
        text-align: center;

        @include mq(md) {
            padding-top: $page-header-spacing * 2;
            padding-bottom: $page-header-spacing * 2;
        }

        &--with-bgimage {
            position: relative;
            color: #fff;

            .container {
                position: relative;
                z-index: 3;
            }

            &::after {
                @include coverall;
                z-index: 1;
                content: '';
                display: block;
                background-color: rgba(0, 0, 0, 0.4);
            }

            @include mq(md) {
                min-height: 480px;
            }
        }
    }
    &__title {
        @include font-size-xl;
        margin: 0 auto;
        max-width: 40ch;

        @include mq-down(lg) {
            @include hyphenate;
        }
    }
    &__intro {
        margin: 0 auto;
        max-width: 75ch;
        line-height: 1.944444444;
        color: $gray;
    }
    &__intro,
    &__meta {
        margin-top: 2rem;
    }
    &__image {
        position: relative;
        min-height: 200px;
        overflow: hidden;
        border-radius: var(--border-radius-large);

        @include mq(md) {
            min-height: 400px;
        }
        @include mq(lg) {
            min-height: 600px;
        }
    }
}

// VARIANTS

// Hardware Index Page
.page--hardware {
    .page__header {
        background-color: $gray-lightest;
    }
}

// Angebot / Offers Page
.page--offers {
    .page__header {
        padding-bottom: 6rem;
    }
}

// Blog Post Page
.page--post {
    .page__header {
        background-color: $gray-lightest;
    }
    .page__tags {
        font-size: 1.125rem;
        padding-top: 1rem;
    }
    .page__tag {
        display: inline-block;
        padding: 0.25rem;
    }
    .page__content {
        padding-bottom: 5rem;
        @include mq(lg) {
            padding-bottom: 7rem;
        }
    }
}

// Default Text Page
.page--text {
    .page__content {
        padding-bottom: 4rem;
    }
    .page__hero {
        margin-bottom: 4rem;
    }
}
