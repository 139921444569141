.featuredposts {
    text-align: center;
    padding-top: 1rem;

    a {
        color: #fff;
        padding: 0.5rem 0;
        display: block;
        outline-color: #fff !important;
        text-decoration: underline;

        @include hover-focus {
            color: #fff;
            text-decoration: none;
        }
    }

    @include mq(lg) {
        display: grid;
        justify-content: center;
        grid-template-columns: repeat(auto-fit, minmax(1px, 30%));
        gap: 2rem;

        a {
            text-decoration: none;
            @include hover-focus {
                text-decoration: underline;
            }
        }
    }
}
