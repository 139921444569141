.cookieconsent {
    height: 100%;
    display: flex;
    flex-direction: column;

    &__header {
        position: relative;
        padding-right: 3rem;
        flex: none;
    }
    &__main {
        @include scrollable;
        flex: 1 0 0%;
        margin: 1rem 0;
    }
    &__footer {
        padding-top: 0.5rem;
        flex: none;
    }

    &__title {
        font-size: 1.5rem;
        padding-top: 0.25rem;
    }
    &__lang {
        position: absolute;
        top: 0;
        right: 0;
    }
    &__actions {
        padding: 1px;
        margin-bottom: 1rem;

        @include mq(md) {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 1rem;

            .btn--block {
                margin: 0;
            }
        }
    }
    &__form {
        margin-top: 2rem;

        legend {
            font-size: 1.125rem;
            font-weight: 600;
            margin-bottom: 1rem;
        }
    }
    &__settings {
        display: grid;
        gap: 1rem;

        @include mq(md) {
            font-size: var(--richtext-size-md, 1.125rem);
            grid-template-columns: repeat(3, 1fr);
        }
    }
    &__pagelinks {
        text-align: center;
        color: $gray-light;

        a {
            color: $gray;
            @include hover-focus {
                color: #000;
            }
        }

        @include mq(md) {
            text-align: right;
        }
    }
}
