//--------------------
// SCSS CUSTOM FUNCTIONS
//--------------------

// z-index layering
@function z($layer) {
    @if map-has-key($z-layers, $layer) == false {
        @warn "No layer found for `#{$layer}` in $z-layers map. Property omitted.";
    }

    @return map-get($z-layers, $layer);
}

// strip units
@function strip-units($number) {
    @return $number / ($number * 0 + 1);
}

@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
    $min: map-get($breakpoints, $name);
    @return if($min != 0, $min, null);
}

// Create several lines of various height
// @args[0]: Color of the first line
// @args[1]: Height of the first line
// @args[2]: Color of the second line
// @args[3]: Height of the second line
// ...
// @args[(n-1)*2]: Color of the nth line
// @args[(n-1)*2 + 1]: Width of the nth line
@function lines($args...) {
    // Default values
    $start: 0;
    $end: 0;
    $result: ();
    @for $i from 1 to length($args) + 1 {
        // Only interested in even numbers
        @if ($i % 2 == 0) {
            $color: nth($args, $i - 1);
            $width: nth($args, $i);
            $end: $start + $width;
            $result: append($result, $color round($start), comma);
            $result: append($result, $color round($end), comma);
            $start: $end;
        }
    }
    @return linear-gradient(180deg, $result);
}

// Two lines of equal height
// @args top: Color of top line
// @args bottom: Color of bottom line
@function two-lines($top, $bottom) {
    @return lines($top, 50%, $bottom, 50%);
}

// Three lines of equal height
// @args top: Color of top line
// @args center: Color of center line
// @args bottom: Color of bottom line
@function three-lines($top, $center, $bottom) {
    @return lines($top, 33.33%, $center, 33.33%, $bottom, 33.33%);
}