.category {
    &__title {
        font-weight: 700;
        margin-bottom: 0.5rem;
        font-size: 1.125rem;
        font-family: $font-family-base;
        letter-spacing: 0.15px;
        line-height: 1.5;

        a {
            display: block;
            color: $body-color;

            @include hover-focus {
                color: $body-color;
                text-decoration: underline;
            }
        }
    }

    &__link {
        padding: 1rem 0;
        display: block;

        @include hover-focus {
            text-decoration: underline;
        }
    }
}
