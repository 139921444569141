.breadcrumbs {
    margin: 2rem 0;
    font-size: 0.875rem;
    color: $brand-blue;
    display: flex;
    list-style: none;

    &__item {
        margin-right: 0.3rem;

        &--active {
            margin-left: 0.3rem;
        }
    }

    &__link {
        padding: 0.5rem 0.35rem 0.5rem 0;

        @include hover-focus {
            text-decoration: underline;
        }
    }
}
