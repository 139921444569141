// Keyframe Animation Definitions

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

@keyframes pulse {
    from {
        transform: scale(1);
    }
    to {
        transform: scale(1.05);
    }
}

// https://ryanmulligan.dev/blog/css-marquee/
@keyframes marquee {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100% - var(--gap)));
  }
}