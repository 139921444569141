// Icon Component
.icon {
    display: inline-block;
    font-size: 1.5em;
    height: 1em;
    width: 1em;
    vertical-align: middle;
    fill: currentColor;
}

// ==========================
// Animated Pure CSS Menu Burger Icon
// ==========================

.menuicon {
    display: block;
    width: 18px;
    height: 14px;
    position: relative;
    transform: rotate(0deg) scaleX(-1);
    transition: transform 0.3s $animation-curve-default;
    cursor: pointer;
    margin: 0 auto;

    &__bar {
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        height: 2px;
        width: 100%;
        background-color: currentColor;
        transition: all 0.25s ease-in-out;
        transform: rotate(0);

        &:nth-child(1) {
            top: 0px;
            width: 120%;
        }
        &:nth-child(2),
        &:nth-child(3) {
            top: 50%;
            width: 80%;
        }
        &:nth-child(4) {
            top: 100%;
        }
    }

    // Active State: Transform to "X"
    .is-open & {
        top: 1px;
        transform: rotate(-180deg) scaleX(-1);

        .menuicon__bar {
            &:nth-child(1),
            &:nth-child(4) {
                width: 0;
                top: 50%;
                left: 50%;
            }
            &:nth-child(2) {
                width: 100%;
                transform: rotate(45deg);
            }
            &:nth-child(3) {
                width: 100%;
                transform: rotate(-45deg);
            }
        }
    }
}
