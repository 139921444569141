.expandinglist {
    font-size: 0.875rem;
    border-top: solid 1px $gray-light;
    border-bottom: solid 1px $gray-light;

    &__title {
        display: block;
        flex: 1 0 auto;
    }

    &__togglebtn {
        @include button-reset;
        display: flex;
        width: 100%;
        padding: 1.125rem 0;
        color: $body-color;
        text-align: left;
        font-weight: 600;
        position: relative;
    }

    &__toggle-text {
        flex: 1 0 auto;
    }

    &__toggle-indicator {
        display: block;
        position: relative;
        right: -3px; // optical alignment
        color: var(--color-primary);
        transform: rotate(0);
        transition: transform 0.25s $animation-curve-default;

        &.pointing-upwards {
            transform: rotate(-180deg);
        }
    }

    &__content {
        display: none;

        &.is-open {
            display: block;
        }
    }

    @include mq(lg) {
        border: 0;

        &__content {
            display: block !important;
        }
        &__togglebtn {
            pointer-events: none;
            padding-top: 0;
        }
        &__toggle-indicator {
            display: none;
        }
    }
}
