.cardgrid {
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(auto-fit, minmax(300px, 375px));
    grid-gap: 2rem;

    &__item {
        display: flex;
        flex-direction: column;
        text-align: center;
    }
}
