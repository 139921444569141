// UTILITY CLASSES
// All of these have !important because they should always overrule any other style

// Layout Utils

.u-mt0 {
    margin-top: 0 !important;
}
.u-mt05 {
    margin-top: 0.5rem !important;
}
.u-mt1 {
    margin-top: 1rem !important;
}
.u-mt2 {
    margin-top: 2rem !important;
}
.u-mt3 {
    margin-top: 3rem !important;
}
.u-mt4 {
    margin-top: 4rem !important;
}

.u-mb0 {
    margin-bottom: 0 !important;
}
.u-mb1 {
    margin-bottom: 1rem !important;
}
.u-mb2 {
    margin-bottom: 2rem !important;
}
.u-mb3 {
    margin-bottom: 3rem !important;
}
.u-mb4 {
    margin-bottom: 4rem !important;
}

.u-pt0 {
    padding-top: 0 !important;
}
.u-pt1 {
    padding-top: 1rem !important;
}
.u-pt2 {
    padding-top: 2rem !important;
}
.u-pt4 {
    padding-top: 4rem !important;
}
.u-pt5 {
    padding-top: 5rem !important;
}
.u-pt6 {
    padding-top: 6rem !important;
}

.u-pb0 {
    padding-bottom: 0 !important;
}
.u-pb1 {
    padding-bottom: 1rem !important;
}
.u-pb2 {
    padding-bottom: 2rem !important;
}
.u-pb4 {
    padding-bottom: 4rem !important;
}
.u-pb5 {
    padding-bottom: 5rem !important;
}
.u-pb6 {
    padding-bottom: 6rem !important;
}
.u-pb7 {
    padding-bottom: 7rem !important;
}

// Text Utilities

.u-align-left {
    text-align: left !important;
}
.u-align-center {
    text-align: center !important;
}
.u-align-right {
    text-align: right !important;
}
.u-font-size-xl {
    @include font-size-xl;
}

.u-font-size

// responsive object embed helpers
.u-embed {
    position: relative;
    height: 0;

    &--16-9 {
        padding-bottom: 56.25% !important;
    }

    &--4-3 {
        padding-bottom: 75% !important;
    }

    video,
    iframe {
        @include coverall;
        width: 100% !important;
        height: 100%;
        z-index: 2;
    }
}

// Display Utils

.u-mobile-only {
    @include mq(lg) {
        display: none !important;
    }
}
.u-tablet-only {
    @include mq-down(md) {
        display: none !important;
    }
}
.u-desktop-only {
    @include mq-down(lg) {
        display: none !important;
    }
}
.u-print-only {
    @media not print {
        display: none !important;
    }
}

// Misc

.u-bg-pipedrive {
    // match the pipedrive iframe bg color
    background-color: #f7f7f7;
}
