.postmeta {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 2rem 0;

    &__image {
        margin-right: 1rem;
    }
    &__sharing {
        flex-basis: 100%;
        padding-top: 2rem;
    }

    @include mq(md) {
        padding: 4rem 0;

        &__sharing {
            flex-basis: auto;
            margin-left: auto;
            padding-top: 0;
            padding-left: 2rem;
        }
    }
}
