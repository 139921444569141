.teamlist {
    display: block;

    &__item {
        text-align: center;
    }
    &__image {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 auto 1rem;
    }
    &__name {
        font-family: $font-family-display;
        font-weight: 500;
        line-height: 1.4;
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;
    }
    &__role,
    &__phone {
        color: $gray;
        display: block;
    }
}

.teamlist--default {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .teamlist__item {
        padding: 2rem;
        margin: 0 1rem;
    }
    .teamlist__name {
        font-size: 1.0625rem; //17px
    }
}

.teamlist--large {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    grid-gap: 1rem;
    max-width: 1060px;
    margin: 0 auto;

    .teamlist__image {
        background-color: #fff;
        border-radius: 15px;
        box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
        padding: 3px;

        > img {
            border-radius: 11px;
            width: 100%;
            height: auto;
        }
    }

    @include mq(md) {
        grid-template-columns: repeat(auto-fill, minmax(235px, 1fr));
        grid-gap: 2rem;

        .teamlist__name {
            font-size: 1.4375rem;
        }
    }
}
