.referencelist {
    display: grid;
    gap: 3rem;

    @include mq(md) {
        gap: 4rem;
        grid-template-columns: 1fr 1fr;
    }
    @include mq(xl) {
        gap: 5rem;
        grid-template-columns: 1fr 1fr 1fr;
    }

    &__item {
        &--featured {
            grid-column: 1 / -1;
        }
    }
}
