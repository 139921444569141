.pagination {
    position: relative;
    padding: 4rem 0;

    @include mq(md) {
        padding-top: 6rem;
    }

    &__inner {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    &__link {
        display: flex;
        align-items: center;
        font-family: $font-family-base;

        &--disabled {
            pointer-events: none;
            color: $gray-light;
        }
    }

    &__loadmore {
        @include button-reset;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: 1rem;
        font-weight: 600;
        margin: 0 auto;
        color: var(--color-primary);

        &:disabled {
            visibility: hidden;
        }
    }

    &__status {
        display: none;
        text-align: center;
    }
}
