// Toast Messages

.toast {
    display: flex;
    padding: 1rem;

    position: fixed;
    bottom: 0;
    left: 1rem;
    right: 1rem;

    border-width: 1px;
    border-style: solid;
    border-radius: var(--border-radius) var(--border-radius) 0 0;
    box-shadow: $box-shadow-elevated;

    will-change: transform;
    transform: translateY(100%);
    transition: transform 0.25s $animation-curve-default;
    pointer-events: none;
    z-index: z('messages');

    &__message {
        flex: 1 0 0%;
        padding-right: 1rem;
    }
    &__actions {
        text-align: left;
    }

    // Show Toast
    &--active {
        transform: translate(0, 0) !important;
        pointer-events: auto;
        transition: transform 0.25s $animation-curve-linear-out-slow-in;
    }

    // Hide Toast
    &--idle {
        width: 0;
        visibility: hidden;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        clip-path: inset(50%);
        transition: none;
    }

    // Type Classes
    // related to django message tags
    &--default {
        color: #fff;
        background-color: $gray-darker;
        border-color: $gray-darkest;
    }
    &--info {
        @extend .alert--info;
    }
    &--warning {
        @extend .alert--warning;
    }
    &--error {
        @extend .alert--error;
    }
    &--success {
        @extend .alert--success;
    }

    // Breakpoints
    @include mq(md) {
        width: 340px;
        bottom: auto;
        left: auto;
        right: 2rem;
        top: 2rem;
        border-radius: var(--border-radius);
        transform: translateX(150%);
    }
}
