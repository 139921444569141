.tabs {
  // most important setting to make tablist behave nicely on mobile. do not remove, or
  // weird things will happen when user interacts with tabbar.
  overflow-x: hidden;
  position: relative;

  &:before, &:after {
    position: absolute;
    top: 0;
    content: " ";
    width: 10%;
    height: 2.875rem;
    z-index: z('content-overlay');
    pointer-events: none;
  }
  &:before {
    left: 0;
    background: linear-gradient(90deg, white, rgba(255, 255, 255, 0));
  }
  &:after {
    right: 0;
    background: linear-gradient(270deg, white, rgba(255, 255, 255, 0));
  }

  &__tablist {
    display: flex;
    margin-bottom: 2rem; // half of 3.125, but jose wants 30px
    transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1);

    @include mq(md) {
      justify-content: center;
    }
    @include mq(lg) {
      margin-bottom: 5rem;
    }

    &__tab {
      @include button-reset();
      font-size: 1.4375rem; //23px
      font-weight: 500;
      padding-left: 2rem;
      padding-right: 2rem;
      padding-bottom: 0.65rem;
      border-bottom: 2px solid $gray-lighter;
      font-family: $font-family-display;
      color: #A8A8A8;
      max-width: 100%;

      &[aria-selected] {
        border-bottom-color: $brand-blue;
        color: $body-color;
      }
    }
  }

  &__tabcontent {
    margin-top: 0;
    display: none;

    .teaser__col--image {
      margin-bottom: 1.5625rem;  // 3.125 / 2
    }

    &--active {
      display: block;
    }
  }
}