.stats {
  display: flex;
  flex-direction: column;
  gap: clamp(1.875rem, 7vw, 4.5rem);
  justify-content: space-between;
  font-family: $font-family-display;


  @include mq(md) {
    flex-direction: row;
    align-items: flex-end;
  }

  &__item {
    > * + * {
      margin-top: .875rem;
    }
  }

  &__label {
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 2;
    white-space: nowrap;

    @include mq(md) {
      flex-direction: row;
      font-size: clamp(1rem, 2vw, 1.5rem);
      //font-size: 1.5rem; // this is a bit smaller than the design suggests, in order to make sure it won't wrap into a new line on safari.
    }
  }

  &__value {
    font-size: 3.125rem;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 0;

    @include mq(md) {
      font-size: clamp(1rem, 4vw, 4.0625rem);
      //font-size: 4.0625rem;
    }
  }
}