.offerlist {
    display: grid;
    gap: 2rem;

    @include mq(lg) {
        grid-template-columns: repeat(3, 1fr);
    }

    &__item {
        display: flex;
        flex-direction: column;
    }
}
