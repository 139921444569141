.sharing {
    &__link {
        display: inline-flex;
        font-size: 1rem;
        width: 2.5rem;
        height: 2.5rem;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: $gray-light;
        color: $gray-darkest;

        & + & {
            margin-left: 0.5rem;
        }

        @include hover-focus {
            background-color: var(--color-primary);
            color: #fff;
        }
    }
}
